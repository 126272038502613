import React, { useLayoutEffect, useRef, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fadeInUp } from '../../utils/transitions';
import useWindowSize from '../../hooks/useWindowSize';

const Sentence = styled.span`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ textAlign }) =>
    textAlign === 'center' ? 'center' : 'start'};
  white-space: pre;
`;

export default function SplitLines({
  className,
  children,
  visible,
  textAlign,
  delay = 0,
}) {
  const wrapper = useRef(null);
  const words = children.split(/(\s+)/);
  const wordRefs = useRef([...Array(words.length)].map(() => createRef()));
  const windowSize = useWindowSize();
  const [lines, setLines] = useState([]);
  const [firstWordsInLine, setFirstWordsInLine] = useState([]);

  function getOffset(parent, child) {
    const parentPos = parent.getBoundingClientRect();
    const childPos = child.getBoundingClientRect();
    return {
      top: childPos.top - parentPos.top,
      right: childPos.right - parentPos.right,
      bottom: childPos.bottom - parentPos.bottom,
      left: childPos.left - parentPos.left,
    };
  }

  useLayoutEffect(() => {
    function getLine(parent, child) {
      const parentHeight = parent.offsetHeight;
      const childHeight = child.offsetHeight;
      const linesLength = parentHeight / childHeight;
      const childOffset = getOffset(parent, child).top;

      const line = (childOffset / parentHeight) * linesLength;
      return line + 1;
    }

    function getFirstWords(parent, child) {
      return getOffset(parent, child).left === 0;
    }

    const linePositions = wordRefs.current.map((word) =>
      getLine(wrapper.current, word.current)
    );
    setLines(linePositions);
    const firstWords = wordRefs.current.map((word, i) =>
      getFirstWords(wrapper.current, word.current, i)
    );
    setFirstWordsInLine(firstWords);
  }, [windowSize.width]);
  return (
    <Sentence
      aria-label={children}
      className={className}
      ref={wrapper}
      textAlign={textAlign}
    >
      {words.map((word, i) => (
        <span
          aria-hidden="true"
          key={i}
          hidden={firstWordsInLine[i] && word === ' '}
          css={fadeInUp(visible)}
          style={{ transitionDelay: `${lines[i] * 0.1 + delay}s` }}
          ref={wordRefs.current[i]}
        >
          {word}
        </span>
      ))}
    </Sentence>
  );
}

SplitLines.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  textAlign: PropTypes.string,
  visible: PropTypes.bool,
  delay: PropTypes.number,
};
