import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Button from './Button';
import Grid from './Grid';
import { H2 } from './Typography';

import { localizeStaticQuery } from '../../utils/localization';

import LocaleContext from '../../context/LocaleContext';

const Wrapper = styled(Grid)`
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  margin-bottom: -4vw;
  justify-items: center;
  text-align: center;
  > * {
    grid-column: col / span 12;
  }
`;

export default function PreFooter({ className }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          sanitySettings {
            preFooterText {
              _type
              en
              fr
            }
            preFooterButton {
              _type
              en
              fr
            }
          }
        }
      `}
      render={(settings) => {
        const { locale } = useContext(LocaleContext);
        const localizedSettings = localizeStaticQuery(locale.id)(
          settings.sanitySettings
        );
        return (
          <Wrapper paddedTop paddedBottom className={className}>
            <H2 as="h2">{localizedSettings.preFooterText}</H2>
            <Button color="black" to="/contact/">
              {localizedSettings.preFooterButton}
            </Button>
          </Wrapper>
        );
      }}
    />
  );
}

PreFooter.propTypes = {
  className: PropTypes.string,
};
